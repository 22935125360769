import { Injectable } from '@angular/core';
import { UIBreadcrumb, UITitlePage } from '../models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private subjectBreadcrumb = new Subject<UIBreadcrumb[]>();
  private subjecttitle = new Subject<UITitlePage>();

  public get breadcrumbList() {
    return this.subjectBreadcrumb.asObservable();
  }

  public get titlePage() {
    return this.subjecttitle.asObservable();
  }

  constructor() {}

  public setBreadcrumb(menu: UIBreadcrumb[]) {
    this.subjectBreadcrumb.next(menu);
  }

  public setTitlePage(title:UITitlePage){
    this.subjecttitle.next(title);
  }
}
