<nz-table
  #Table
  class="dynamic-table"
  [nzData]="displayData"
  [nzSize]="'middle'"
  [nzBordered]="configs?.bordered ?? true"
  [nzTitle]="null"
  [nzTableLayout]="'auto'"
  [nzShowPagination]="configs?.pagination ?? true"
  [nzTotal]="dataSet?.count ?? 0"
  [nzFrontPagination]="!configs?.loadOnDemand"
  [nzPageIndex]="dataSet?.pageIndex!"
  [nzPageSize]="dataSet?.pageSize!"
  [nzLoading]="loading"
  [nzShowSizeChanger]="configs?.simplePagination ?? false"
  [nzHideOnSinglePage]="false"
  (nzPageIndexChange)="onPageIndexChange($event)"
  (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
  (nzQueryParams)="onQueryParams($event)"
>
  <thead>
    <tr>
      <th
        *ngIf="configs?.checkbox"
        nzWidth="60px"
        [(nzChecked)]="allChecked"
        [nzLeft]="(configs?.checkbox && configs?.isFixedLeftCheck) ?? false"
        [nzIndeterminate]="isIndeterminate"
        (nzCheckedChange)="onCheckAll($event)"
      ></th>
      <th
        *ngFor="let col of columnsDisplay"
        [nzWidth]="col?.width ?? null"
        [nzLeft]="(col.isFixedLeft ?? false) && !isSmallScream"
        [nzRight]="(col.isFixedRight ?? false) && !isSmallScream"
        [nzColumnKey]="col.field"
      >
        {{ col.displayName }}
      </th>
      <th
        *ngIf="configs?.enabledActionColumn"
        [nzRight]="(configs?.actionColumnFixed ?? false) && !isSmallScream"
        [nzWidth]="configs?.actionColumnWidth ?? '25%'"
      >
        Acciones
      </th>
      <th nzWidth="60px" *ngIf="isSmallScream"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let row of Table.data; index as i">
      <tr>
        <td
          *ngIf="configs?.checkbox"
          [nzLeft]="(configs?.checkbox && configs?.isFixedLeftCheck) ?? false"
          [(nzChecked)]="row.checked"
          [nzShowCheckbox]="configs?.checkbox"
          (nzCheckedChange)="refreshStatus()"
        ></td>
        <td
          *ngFor="let col of columnsDisplay"
          [nzLeft]="(col.isFixedLeft ?? false) && !isSmallScream"
          [nzRight]="(col.isFixedRight ?? false) && !isSmallScream"
          nzBreakWord
          class="td-content"
        >
          <ng-container
            [ngTemplateOutlet]="rows"
            [ngTemplateOutletContext]="{ data: row, column: col,  isColmunHide:false }"
          ></ng-container>
        </td>
        <td
          *ngIf="configs?.enabledActionColumn"
          [nzRight]="(configs?.actionColumnFixed ?? false) && !isSmallScream"
        >
          <ng-container
            [ngTemplateOutlet]="actions"
            [ngTemplateOutletContext]="{ data: row }"
          ></ng-container>
        </td>
        <td [(nzExpand)]="row.expand" *ngIf="isSmallScream"></td>
      </tr>
      <tr [nzExpand]="row.expand" *ngIf="isSmallScream">
        <ng-container *ngFor="let col of columnsHide">
          <ng-container
            [ngTemplateOutlet]="rows"
            [ngTemplateOutletContext]="{ data: row, column: col, isColmunHide:true }"
          ></ng-container>
        </ng-container>
      </tr>
    </ng-container>
  </tbody>
</nz-table>

<ng-template #rows let-row="data" let-col="column" let-show="isColmunHide">
  <div class="row" [ngClass]="{'row-responsive':show}">
    <span class="colname-responsive" *ngIf="show">{{col.displayName}}: </span>
    <span [ngSwitch]="col.type">
      <span *ngSwitchCase="'custom'">
        <ng-container
          [ngTemplateOutlet]="customCell"
          [ngTemplateOutletContext]="{ data: row }"
        ></ng-container>
      </span>
      <span *ngSwitchCase="'link'" class="span-link">
        <a (click)="onClickLink(row)" class="span-link__data">{{
          row[col.field]
        }}</a>
      </span>
      <span *ngSwitchCase="'tag'">
        <nz-tag [nzColor]="setColorTag(row[col.field], col.tagCondition!)">
          {{ row[col.field] }}</nz-tag
        >
      </span>
      <span *ngSwitchCase="'switch'">
        <nz-switch
          nzSize="small"
          [ngModel]="row['rowStatus'] ?? false"
          [nzCheckedChildren]="col.switchLabel?.labelOn ?? 'On'"
          [nzUnCheckedChildren]="col.switchLabel?.labelOff ?? 'Off'"
          (ngModelChange)="handleSwitch($event, row)"
          [nzDisabled]="col.disabled ? true : false"
        >
        </nz-switch>
      </span>
      <span *ngSwitchDefault>{{ row[col.field] }}</span>
    </span>
  </div>
  
</ng-template>