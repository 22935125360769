import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UTILS } from '@rondaver-project/modules/utils';
import { UI_NGZORRO } from '../ui-ngzorro';

@Component({
  selector: 'ui-card-comments',
  standalone: true,
  imports: [UTILS,UI_NGZORRO],
  templateUrl: './ui-card-comments.component.html',
  styleUrl: './ui-card-comments.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiCardCommentsComponent {
  public comments:any[] = [
    {
      title:'Usuario',
      description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et'
    },
    {
      title:'Usuario',
      description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et'
    },
    {
      title:'Usuario',
      description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et'
    },
    {
      title:'Usuario',
      description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et'
    },
    {
      title:'Usuario',
      description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et'
    }
  ]
}
