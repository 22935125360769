import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UTILS } from '@rondaver-project/modules/utils';
import { UI_NGZORRO } from '../ui-ngzorro';
import { UIMenu } from '../../models';

@Component({
  selector: 'ui-inline-menu',
  standalone: true,
  imports: [UTILS,UI_NGZORRO],
  templateUrl: './ui-inline-menu.component.html',
  styleUrl: './ui-inline-menu.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiInlineMenuComponent {
  @Input({required:true}) 
  public menuTemplate:UIMenu[] = [];
}
