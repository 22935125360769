import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  inject,
} from '@angular/core';
import { UTILS } from '@rondaver-project/modules/utils';
import { UI_NGZORRO } from '../../components/ui-ngzorro';
import version from 'appVersion.json';
import { UIBreadcrumb, UITitlePage } from '../../models';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'ui-panel-layout',
  standalone: true,
  imports: [UTILS, UI_NGZORRO],
  templateUrl: './ui-panel-layout.component.html',
  styleUrl: './ui-panel-layout.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiPanelLayoutComponent {
  public layoutControlService = inject(UiService);
  public router = inject(ActivatedRoute);
  public appVersion: string = version.version;
  public year: number = new Date().getFullYear();
  public isCollapsed: boolean = false;
  public breadCrumbMenu: UIBreadcrumb[] = [];
  public title: UITitlePage | null = null;
  public isSmallScream: boolean = false;

  @Input()
  public enabledFloatButton: boolean = false;

  @Input()
  public urlLogo: string = '';

  @ContentChild('mainContent')
  public content: TemplateRef<any> | null = null;

  @ContentChild('menu')
  public tplMenu: TemplateRef<any> | null = null;

  constructor(private breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe('(max-width: 992px)').subscribe((isMatched) => {
      this.isSmallScream = isMatched.matches;
    });
  }

  public ngOnInit(): void {
    this.layoutControlService.breadcrumbList.subscribe((menu) => {
      this.breadCrumbMenu = menu;
    });

    this.layoutControlService.titlePage.subscribe((title) => {
      this.title = title;
    });
  }

  public handlerOpen(event: any) {
    if (this.isSmallScream) this.isCollapsed = true;
  }
}
