<ul nz-menu nzTheme="dark" nzMode="inline" style="margin-bottom: 80px">
  <ng-container
    *ngTemplateOutlet="menuTpl; context: { $implicit: menuTemplate }"
  ></ng-container>
  <ng-template #menuTpl let-menus>
    @for (menu of menus; track $index) { 
      @if (!menu.children && (menu.visible ?? true)) {
        <li
          nz-menu-item
          nz-tooltip
          nzTooltipPlacement="right"
          [nzDisabled]="menu.disabled"
          [nzSelected]="menu.selected"
          [routerLink]="menu.router ? [menu.router] : []"
          routerLinkActive
          #rla="routerLinkActive"
          [nzSelected]="rla.isActive && menu.router"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <div class="selected-item">
            <ng-container
              *ngIf="menu.iconPackItem === 0 && menu.iconItem; else elseTemplate"
            >
              <nz-space [nzSize]="menu.marginIcon ? menu.marginIcon : 15">
                <span *nzSpaceItem [class]="menu.iconItem"></span>
                <span
                  *nzSpaceItem
                  [innerHtml]="menu.label"
                  style="font-family: Poppins-Regular; font-weight: bold"
                ></span>
              </nz-space>
            </ng-container>
            <ng-template #elseTemplate>
              <span
                nz-icon
                [nzType]="menu.iconItem"
                [nzTheme]="menu.iconTheme ? menu.iconTheme : 'fill'"
                *ngIf="menu.iconItem"
              ></span>
              <span [innerHtml]="menu.label"></span>
            </ng-template>
          </div>
        </li>
    } @else if (menu.children && (menu.visible ?? true)) {
        <li
          nz-submenu
          [nzOpen]="menu.open"
          [nzTitle]="menu.label"
          [nzIcon]="menu.iconSubmenu ? menu.iconSubmenu : 'menu'"
          [nzDisabled]="menu.disabled"
        >
          <ul>
            <ng-container
              *ngTemplateOutlet="menuTpl; context: { $implicit: menu.children }"
            ></ng-container>
          </ul>
        </li>
    }}
  </ng-template>
</ul>
<!-- TODO: [nzPaddingLeft]="menu.level * 24" NO ESTAS CONFIGURADO EN LOS li -->
