<nz-layout>
    <nz-sider
      nzCollapsible
      nzBreakpoint="lg"
      [(nzCollapsed)]="isCollapsed"
      [nzCollapsedWidth]="0"
      [nzTrigger]="null"
    >
      <div nz-row nzJustify="center" nzAlign="middle" class="logo">
        <img [src]="urlLogo"/>
      </div>
      <div nz-row nzJustify="center" nzAlign="middle" style="text-align: center">
        <div nz-col nzSpan="24" style="margin-bottom: 9px">
          <nz-avatar nzIcon="user" [nzSize]="70"></nz-avatar>
        </div>
        <div nz-col nzSpan="24">
          <h4 class="name-profile">Lorem ipsum</h4>
        </div>
        <div nz-col nzSpan="24">
          <button nz-button nzType="primary" class="btn-medium" routerLink="/main/profile">Ver perfil</button>
        </div>
      </div>
      <hr />
      <div nz-row>
        <div nz-col>
          <ng-container *ngTemplateOutlet="tplMenu"></ng-container>
        </div>
      </div>
    </nz-sider>
  
    <div
      *ngIf="enabledFloatButton"
      class="drawer-handle"
      [ngClass]="{ 'drawer-handle-collapsed': isCollapsed }"
    >
      <button
        nz-button
        nzSize="small"
        nzType="text"
        (click)="isCollapsed = !isCollapsed"
      >
        <span
          class="fa-solid"
          [ngClass]="{
            'fa-chevron-left': !isCollapsed,
            'fa-chevron-right': isCollapsed
          }"
        ></span>
      </button>
    </div>
    <nz-layout
      [ngClass]="{
        'right-layout': !isCollapsed,
        'right-layout-collapsed': isCollapsed
      }"
    >
      <nz-header>
        <div class="header-title">
          <button
            nz-button
            nzSize="small"
            nzType="text"
            (click)="isCollapsed = !isCollapsed"
          >
            <span
              class="fa-solid"
              style="color: #fff;"
              [ngClass]="{
                'fa-chevron-left': !isCollapsed,
                'fa-chevron-right': isCollapsed
              }"
            ></span>
          </button>
          <div nz-space nzAlign="center" class="header-title">
            <nz-divider *nzSpaceItem nzType="vertical" ></nz-divider>
            <span *nzSpaceItem [class]="title?.icon"></span>
            <span *nzSpaceItem>{{title?.title}}</span>
          </div>
        </div>
        <div class="header-logout">
          <button nz-button nzSize="default" nzType="text" class="btn-logout">
            <span
              class="fa-solid fa-right-from-bracket"
              style="margin-right: 4px"
            ></span>
            Cerrar sesión
          </button>
        </div>
      </nz-header>
      <nz-content (click)="handlerOpen($event)">
        <nz-breadcrumb *ngIf="breadCrumbMenu.length">
          <nz-breadcrumb-item *ngFor="let item of breadCrumbMenu">
            <a [routerLink]="[item.routerLink]">
              <span *ngIf="item.icon" nz-icon [nzType]="item.icon"></span>
              <span>{{ item.label }}</span>
            </a>
          </nz-breadcrumb-item>
        </nz-breadcrumb>
        <div class="inner-content">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </nz-content>
      <nz-footer>
        <span>Rondaver © {{ year }}</span>
        <span>V.{{ appVersion }}</span>
      </nz-footer>
    </nz-layout>
  </nz-layout>
  