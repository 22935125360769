<nz-table
  #Table
  class="dynamic-config-table"
  [nzData]="dataSet"
  [nzSize]="'middle'"
  [nzBordered]="true"
  [nzShowPagination]="false"
  [nzTitle]="null"
  [nzTableLayout]="'auto'"
>
  <thead>
    <tr>
      <th nzWidth="20%">Nombre</th>
      <th>Activar</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let info of Table.data; index as i">
      <tr class="main-row">
        <td [ngClass]="{ 'cell-disabled': info.disabled }">
          {{ info.name }}
        </td>
        <td>
          <nz-switch
            *ngIf="!info.disabled"
            nzCheckedChildren="1"
            nzUnCheckedChildren="0"
          ></nz-switch>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>
