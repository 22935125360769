import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { UTILS } from '@rondaver-project/modules/utils';
import { UI_NGZORRO } from '../ui-ngzorro';
import { UIColumns, UITableConfigs, UITagCondition } from '../../models';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TableData } from '../../models/class/table.class';

@Component({
  selector: 'ui-table',
  standalone: true,
  imports: [UTILS, UI_NGZORRO],
  templateUrl: './ui-table.component.html',
  styleUrl: './ui-table.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTableComponent implements OnChanges {
  public displayData: any[] = [];
  public allChecked: boolean = false;
  public isIndeterminate: boolean = false;
  public isSmallScream: boolean = false;
  public columnsDisplay: UIColumns[] = [];
  public columnsHide: UIColumns[] = [];

  @Input()
  public dataSet: TableData | null = null;

  @Input()
  public columns: UIColumns[] = [];

  @Input()
  public configs: UITableConfigs | null = null;

  @Input()
  public loading: boolean | null = false;

  @Input()
  public disabledResponsive: boolean = false;

  @Output()
  public pageChangeEvent = new EventEmitter<number>();

  @Output()
  public currentPageDataEvent = new EventEmitter<readonly any[]>();

  @Output()
  public queryParamsEvent = new EventEmitter<NzTableQueryParams>();

  @Output()
  public selectRowEvent = new EventEmitter<any[]>();

  @Output()
  public checkSelectEvent = new EventEmitter<boolean>();

  @Output()
  public clickLinkEvent = new EventEmitter<any>();

  @Output()
  public switchRowEvent = new EventEmitter<{ status: boolean; row: any }>();

  @ContentChild('actions')
  public actions: TemplateRef<any> | null = null;

  @ContentChild('cellTemplate')
  public customCell: TemplateRef<any> | null = null;

  constructor(private _breakpointObserver: BreakpointObserver) {
    _breakpointObserver
      .observe('(max-width: 767px)')
      .subscribe(({ matches }) => {
        this.isSmallScream = matches && !this.disabledResponsive;
        this.setColumns(this.columns, this.isSmallScream);
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.dataSet && this.dataSet.results) {
      this.displayData = ([] as any[]).concat(
        this.dataSet.results.map((item, index) => ({
          ...item,
          expand: false,
        }))
      );
    }

    this.setColumns(this.columns, this.isSmallScream);
  }

  public onClickLink(row: any) {
    this.clickLinkEvent.emit(row);
  }

  public onPageIndexChange(event: number) {
    this.pageChangeEvent.emit(event);
  }

  public onCurrentPageDataChange(event: readonly any[]) {
    this.currentPageDataEvent.emit(event);
  }

  public onQueryParams(event: NzTableQueryParams) {
    this.queryParamsEvent.emit(event);
  }

  public onChecked(event: boolean) {
    this.checkSelectEvent.emit(event);
  }

  public refreshStatus(): void {
    const validData = this.displayData.filter((value) => !value.disabled);
    const allChecked =
      validData.length > 0 &&
      validData.every((value) => value.checked === true);
    const allUnChecked = validData.every((value) => !value.checked);
    this.allChecked = allChecked;
    this.isIndeterminate = !allChecked && !allUnChecked;
    const checkList = this.displayData.filter((value) => value.checked);
    this.selectRowEvent.emit(checkList);
  }

  public onCheckAll(value: boolean): void {
    this.displayData.forEach((data) => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  public handleSwitch(event: boolean, row: any) {
    this.switchRowEvent.emit({
      status: event,
      row,
    });
  }

  public setColumns(columns: UIColumns[], isResponsive: boolean) {
    if (isResponsive && columns.length) {
      const { columnsDisplay, columnsHide } = this.orderColumns(columns);
      this.columnsDisplay = columnsDisplay;
      this.columnsHide = columnsHide;
    } else if (columns.length) {
      this.columnsDisplay = columns;
      if (this.dataSet) {
        this.displayData = ([] as any[]).concat(
          this.dataSet.results.map((item, index) => ({
            ...item,
            expand: false,
          }))
        );
      }
    }
  }

  public orderColumns(columns: UIColumns[]) {
    let columnsList = [...columns];
    let columnsDisplay: UIColumns[] = [],
      columnsHide: UIColumns[] = [],
      colPriority = columnsList.filter((col) => col.priority),
      colNotPriority = columnsList.filter((col) => !col.priority);
    if (colPriority.length) {
      if (colPriority.length === 1) {
        columnsDisplay = columnsDisplay.concat(colPriority[0]);
        columnsHide = columnsHide.concat(colNotPriority);
      } else {
        const [firstCol, ...hiddenCol] = colPriority.sort(
          (a, b) => a.priority! - b.priority!
        );
        columnsDisplay = columnsDisplay.concat(firstCol);
        columnsHide = columnsHide.concat(hiddenCol);
        columnsHide = columnsHide.concat(colNotPriority);
      }

      return {
        columnsDisplay,
        columnsHide,
      };
    }

    const [firstCol, ...hiddenCol] = columnsList;

    return {
      columnsDisplay: columnsDisplay.concat(firstCol),
      columnsHide: columnsHide.concat(hiddenCol),
    };
  }

  public setColorTag(rowValue: any, conditions: UITagCondition[]): string {
    const color = conditions.find((i) => i.value == rowValue)?.color;
    return color ? color : '';
  }
}
