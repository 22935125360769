<nz-card class="card_comments">
  <ng-container *ngFor="let item of comments">
    <div nz-row class="box-comments">
      <div nz-col nzSpan="24" class="box-comments__title">
        {{ item.title }}
      </div>
      <div nz-col nzSpan="24" class="box-comments__description">
        {{ item.description }}
      </div>
    </div>
    <div nz-row>
      <nz-divider></nz-divider>
    </div>
  </ng-container>
</nz-card>
