import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UTILS } from '@rondaver-project/modules/utils';
import { UI_NGZORRO } from '../ui-ngzorro';

@Component({
  selector: 'ui-indicators',
  standalone: true,
  imports: [UTILS,UI_NGZORRO],
  templateUrl: './ui-indicators.component.html',
  styleUrl: './ui-indicators.component.less',
})
export class UiIndicatorsComponent {
  @Input() 
  public title:string='';

  @Input() 
  public value:string='0000';

  @Input()
  public icon:string = '';
}
