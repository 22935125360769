<nz-card class="card_stats">
  <div nz-row [nzGutter]="3" nzAlign="middle" class="my-12">
    <div nz-col nzSpan="6">
      <img nz-image width="32px" [nzSrc]="icon" alt="" />
    </div>
    <div nz-col nzSpan="18">
      <nz-statistic [nzValue]="value" [nzTitle]="title"></nz-statistic>
    </div>
  </div>
</nz-card>
