import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UTILS } from '@rondaver-project/modules/utils';
import { UI_NGZORRO } from '../ui-ngzorro';
import { UITableConfigsAdmin } from '../../models';

@Component({
  selector: 'ui-table-configs',
  standalone: true,
  imports: [UTILS,UI_NGZORRO],
  templateUrl: './ui-table-configs.component.html',
  styleUrl: './ui-table-configs.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiTableConfigsComponent {
  @Input() 
  public dataSet:UITableConfigsAdmin[]=[];

  public changeOption(data:any){
  console.log("🚀 ~ TableConfigsComponent ~ data:", data)

  }
}
