export interface UIMenu {
  level: number;
  label: string;
  labelTooltip?: string;
  iconPackItem?: UIIconPack;
  iconItem?: string;
  iconSubmenu?: string;
  iconTheme?: 'fill' | 'outline' | 'twotone';
  open?: boolean;
  selected?: boolean;
  disabled?: boolean;
  visible?: boolean;
  router?: string | null;
  marginIcon?: number;
  children?: UIMenu[];
}

export enum UIIconPack {
  FONTAWESOME = 0,
  ANTDESING = 1,
}

export interface UIItemFooter {
  label: string;
  iconItem?: string;
  iconTheme?: 'fill' | 'outline' | 'twotone';
  selected?: boolean;
  disabled?: boolean;
  visible?: boolean;
  command?: Function;
}

export interface UIBreadcrumb {
  label: string;
  routerLink: string;
  icon?: string;
  disabled?: boolean;
}

export interface UITitlePage {
  title: string;
  icon: string;
}

export interface UIMenuLanding {
  label: string;
  router?: string | null;
}
