import { UITableData } from "../interfaces/table.interfaces";

export class TableData implements UITableData {
  public pageSize: number;
  public pageIndex: number;
  public count: number;
  public results: any[];

  constructor(private data: any) {
    if (Array.isArray(data)) {
      this.pageIndex = 1;
      this.pageSize = 5;
      this.count = data.length;
      this.results = ([] as any[]).concat(data);
    } else {
      //TODO:IMPLEMENTAR
      this.pageIndex = 1;
      this.pageSize = 5;
      this.count = data.count;
      this.results = ([] as any[]).concat(data.results);
    }
  }
}
